import {
    Box,
    Grid2,
    InputAdornment,
    TextField,
} from "@mui/material";
import { FaMagnifyingGlass } from "react-icons/fa6";
import ProductItem from "./components/ProductItem";
import { useEffect, useState } from "react";
import ShowMoreProducts from "./components/ShowMoreProducts";

export default function ProductGrid() {

    const fake_product = {
        name: "Product 1",
        image_url: "https://picsum.photos/200/300",
        material: "Ouro 18K",
        price: "R$2500,00",
        weight: 10,
        id: 1,
    };

    const fake_response = Array(10)
        .fill(null)
        .map((_, i) => ({ ...fake_product, id: i + 1 }));

    const [products, setProducts] = useState([]);

    useEffect(() => {
        setProducts(fake_response);
    }, []);

    return (
        <Box sx={styles.container}>
            <Box>
                <TextField
                    sx={styles.searchBar}
                    size="small"
                    placeholder="Pesquise por uma palavra"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <FaMagnifyingGlass />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Grid2 container spacing={2} sx={styles.gridContainer}>
                {products.map((product) => (
                    <ProductItem
                        key={product.id}
                        name={product.name}
                        image_url={product.image_url}
                        material={product.material}
                        price={product.price}
                        weight={product.weight}
                        id={product.id}
                    />
                ))}
                <ShowMoreProducts products_shown={10} showMoreFunction={() => {}} total_products={200}/>
            </Grid2>
        </Box>
    );
};

const styles = {
    container: {
        my: "5rem",
    },
    searchBar: {
        display: "flex",
        width: "20rem",
    },
    gridContainer: {
        mt: "2.5rem",
    },
};
