import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/layout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Catalog from "./pages/Catalog";
import ProductDetails from "./pages/ProductDetails";
import CustomOrders from "./pages/CustomOrder";
import { AuthProvider } from "./components/AuthProvider";
import RouteWrapper from "./components/RouteWrapper";
import HomeUser from "./pages/HomeUser";

export default function Router() {

    return (
        <BrowserRouter>
            <AuthProvider>
                <Layout>
                    <Routes>
                        <Route path="/" element={<RouteWrapper defaultComponent={<Home />} loggedComponent={<HomeUser />} />} />
                        <Route path="/login" element={<RouteWrapper defaultComponent={<Login />} loggedComponent={<HomeUser />} />} />
                        <Route path="/home-usuario" element={<RouteWrapper isPrivate defaultComponent={<HomeUser />} loggedComponent={<Login />} />} />
                        <Route path="/catalogo" element={<Catalog />} />
                        <Route path="/produto-detalhes/:id" element={<ProductDetails />} />
                        <Route path="/pedido-personalizado/:id?" element={<RouteWrapper isPrivate defaultComponent={<Login />} loggedComponent={<CustomOrders />} />} />
                        <Route path="*" element={<RouteWrapper defaultComponent={<Home />} loggedComponent={<HomeUser />} />} />
                    </Routes>
                </Layout>
            </AuthProvider>
        </BrowserRouter>
    );
};