import { Typography } from "@mui/material";
import { useContext, useState } from "react";
import { createContext } from "react";
import toast from "react-hot-toast";

export const AuthContext = createContext({});

function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [userName, setUserName] = useState(null);
    const [id, setId] = useState(null);

    function signIn() {
        setUser(true);
        setToken(999999);
        setUserName('Andrey');
        setId(999999);
        localStorage.removeItem('user_token');
        toast.success(<Typography>Seja bem-vindo, Andrey! 😊</Typography>)
    };

    function signOut() {
        setUser(null);
        setToken(null);
        setId(null);
        localStorage.removeItem('user_token');
        toast.success(<Typography>Até a próxima, Andrey! 😊</Typography>)
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                id,
                token,
                userName,
                setToken,
                signOut,
                signIn
            }} >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };