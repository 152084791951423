import { Box, Link as MuiLink, useTheme } from "@mui/material";
import NavBar from "../NavBar";
import { Link } from "react-router-dom";
import { FaRegCircleUser} from "react-icons/fa6";
import { header_content } from "../../../../../data/general_texts";
import ImageNavigation from "../ImageNavigation";

const MainLinksDesktop = ({ isLogged, name, signOut }) => {

    const theme = useTheme();

    return (
        <Box sx={styles.main_links}>
            <ImageNavigation />
            <NavBar />
            {
                isLogged ?
                    <MuiLink disabled sx={styles.login} component={Link} to={'/home-usuario'} textAlign={'center'}>
                        Olá {name}! <Box component={'span'} color={theme.palette.secondary.main} ml={'.5rem'}>Acessar área do cliente <Box component={FaRegCircleUser} ml={'.5rem'} /></Box>
                    </MuiLink>
                    :
                    <MuiLink disabled sx={styles.login} component={Link} to={'/login'} textAlign={'center'}>
                        {header_content.auth_content.text} <Box component={FaRegCircleUser} ml={'.5rem'} />
                    </MuiLink>
            }
        </Box>
    );
};

const styles = {
    main_links: {
        display: {
            xs: 'none',
            md: 'flex'
        },
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    login: {
        color: 'white',
        display: 'flex',
        alignItems: 'center'
    },
};

export default MainLinksDesktop;