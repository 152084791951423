import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "../../theme/customerTheme";
import { Toaster } from "react-hot-toast";

export default function ComponentProvider({ children }) {

    return (
        <ThemeProvider theme={theme}>
            {children}
            <Toaster
                position='top-center'
                reverseOrder={false}
                duration={3000}
            />
            <CssBaseline />
        </ThemeProvider>
    );
};