import { Grid2 } from "@mui/material";
import { StyledSection } from "../../components/Section";
import CtaContent from "./Components/CtaContent";
import { LoginForm } from "./Components/LoginForm";
import CustomContainer from "../../components/CustomContainer";

const styles = {
    section: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'white',
        minHeight: '90vh'
    },
    grid: {
        p: '2rem'
    },
    gridSpacing: { xs: 10, md: 20 }
};

export default function Login() {

    return (
        <CustomContainer>
            <StyledSection sx={styles.section}>
                <Grid2 container spacing={styles.gridSpacing} sx={styles.grid}>
                    <CtaContent />
                    <LoginForm />
                </Grid2>
            </StyledSection>
        </CustomContainer>
    );
};