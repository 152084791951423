import { useParams } from "react-router-dom";
import { StyledSection } from "../../components/Section";
import ProductDescription from "./components/ProductDescription";
import ProductImages from "./components/ProductImages";
import ProductSelection from "./components/ProductSelection";
import { useEffect } from "react";
import { Grid2 } from "@mui/material";

const styles = {
    section: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'white',
        minHeight: '90vh',
        py: '5rem'
    },
    gridSpacing: { xs: 10, md: 20 }
};

export default function ProductDetails() {

    const { id } = useParams();

    useEffect(()=>{
        console.log(id);
    },[]);

    return (
        <StyledSection sx={styles.section}>
            <Grid2 container spacing={5}>

                <ProductImages />

                <ProductSelection />

                <ProductDescription />

            </Grid2>
        </StyledSection>
    );
};