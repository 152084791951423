import CustomContainer from "../../components/CustomContainer";
import Banner from "./Components/Banner";
import ProductGrid from "./Components/ProductGrid";

export default function Catalog() {

    return (
        <>
            <Banner />
            <CustomContainer>
                <ProductGrid />
            </CustomContainer>
        </>
    );
};