import { Button, Grid2, Typography } from "@mui/material";

export default function CtaContent() {

    return (
        <Grid2 size={{ xs: 12, md: 6 }}>
            <Typography variant="h2">Tenha acesso à nossa área exclusiva para empresas</Typography>
            <Typography my={'1.5rem'}>Gostaria de ser um parceiro Inovart Gold?
                Tenha um catálogo prórpio, faça pedido de joias customizadas para seus clientes, receba novidades em primeira mão e muito mais!</Typography>

            <Button variant="contained" color="secondary">
                Converse com nossos especialistas
            </Button>
        </Grid2>
    );
};