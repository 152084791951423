import { Box, Button, FormControl, Grid2, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { FaCartPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function ProductSelection() {

    return (
        <Grid2 size={{ xs: 12, md: 6 }} sx={styles.gridContainer}>
            <Box sx={styles.productInfo}>
                <Typography mb={".5rem"}>Referência #00000</Typography>
                <Typography textAlign={"left"} component="h1" sx={styles.title}>
                    Aliança anatômica, meia cana, frisada, 5mm, com 1pt de Diamante
                </Typography>
            </Box>

            <Box sx={styles.formContainer}>
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <FormControl required fullWidth>
                            <InputLabel id="size-1-label">Tamanho do primeiro aro</InputLabel>
                            <Select
                                fullWidth
                                variant="standard"
                                labelId="size-1-label"
                                id="size-1-select"
                            >
                                {Array(20)
                                    .fill("")
                                    .map((_, index) => (
                                        <MenuItem key={index} value={index + 10}>
                                            {index + 10}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <FormControl required fullWidth>
                            <InputLabel id="size-2-label">Tamanho do segundo aro</InputLabel>
                            <Select
                                variant="standard"
                                labelId="size-2-label"
                                id="size-2-select"
                            >
                                {Array(20)
                                    .fill("")
                                    .map((_, index) => (
                                        <MenuItem key={index} value={index + 10}>
                                            {index + 10}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <TextField
                            required
                            variant="standard"
                            label={"Gravação do primeiro aro"}
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <TextField
                            required
                            variant="standard"
                            label={"Gravação do segundo aro"}
                            fullWidth
                        />
                    </Grid2>
                </Grid2>
            </Box>

            <Box sx={styles.priceInfo}>
                <Typography variant="h3">R$2.000,00</Typography>
                <Typography>Prazo de confecção: 3 dias</Typography>
            </Box>

            <Box sx={styles.buttonContainer}>
                <Button component={Link} to={`/pedido-personalizado/${10}`} size="small" sx={styles.customButton} variant="text">
                    Customizar joia
                </Button>
                <Button
                    startIcon={<FaCartPlus />}
                    size="small"
                    variant="contained"
                    color="secondary"
                >
                    Fazer pedido
                </Button>
            </Box>
        </Grid2>
    );
};

const styles = {
    gridContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    productInfo: {
        marginBottom: "1.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
    },
    title: {
        color: "black",
        fontSize: "1.5rem",
    },
    formContainer: {
        width: "100%",
    },
    priceInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        my: "1.5rem",
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "end",
    },
    customButton: {
        color: "black",
        marginRight: "1.5rem",
    },
};

