import { Box, Button, Grid2, Link, Typography } from "@mui/material";

export default function ShowMoreProducts({ products_shown, total_products, showMoreFunction }) {

    return (
        <Grid2 size={12}>
            <Box sx={styles.footer}>
                <Typography>
                    Mostrando <strong>{products_shown}</strong> de{" "}
                    <strong>{total_products}</strong> joias
                </Typography>
                <Button
                    onClick={showMoreFunction}
                    size="small"
                    sx={styles.footerButton}
                    variant="outlined"
                >
                    Ver mais
                </Button>
                <Link href="#banner">Voltar ao topo</Link>
            </Box>
        </Grid2>
    );
};

const styles = {
    footer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pt: "2.5rem",
    },
    footerButton: {
        color: "black",
        my: "1.5rem",
    },
};