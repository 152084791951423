import { useState } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import PromotionBanner from './component/PromotionBanner/index.js';
import MainLinksDesktop from './component/MainLinksDesktop/index.js';
import MainLinksMobile from './component/MainLinksMobile/index.js';
import CustomDrawer from './component/CustomDrawer/index.js';
import { Box } from '@mui/material';

export default function Header() {

    const { user, userName, signOut } = useAuth();

    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
        <Box component={'header'} sx={styles.header}>
            <CustomDrawer open={open} toggleDrawer={toggleDrawer} />
            <PromotionBanner isLogged={user} />
            <PromotionBanner isLogged={user} isMobile />
            <MainLinksDesktop isLogged={user} name={userName} signOut={signOut} />
            <MainLinksMobile toggleDrawer={toggleDrawer} />
        </Box>
    );
};

const styles = {
    header: {
        position: 'fixed',
        width: '100vw',
        zIndex: 1200,
        bgcolor: '#231F20'
    },
};