import { Box, Link as MuiLink } from "@mui/material";
import { header_content } from "../../../../../data/general_texts";

const links_list = header_content.main_links;

const NavBar = () => {

    return (
        <Box sx={styles.list_link}>
            {
                links_list.map((link, index) => (
                    <MuiLink
                        key={index}
                        href={link.link}
                        sx={styles.link}>
                        {link.title}
                    </MuiLink>
                ))
            }
        </Box>
    );
};

const styles = {
    list_link: {
        display: {
            xs: 'none',
            lg: 'flex'
        },
        justifyContent: 'center',
        alignItems: 'center',
        py: '1.5rem'
    },
    link: {
        color: 'white',
        ':not(:last-child)': {
            mr: '2.5rem'
        }
    },
};

export default NavBar;