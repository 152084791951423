import { Box, Grid2 } from "@mui/material";

export default function ProductImages() {

    return (
        <Grid2 size={{ xs: 12, md: 6 }} sx={styles.container}>
            <Box sx={styles.thumbnails}>
                {Array(3)
                    .fill("")
                    .map((_, index) => (
                        <Box
                            key={index}
                            component={"img"}
                            src="https://picsum.photos/150/150"
                            sx={styles.thumbnailImage}
                        />
                    ))}
            </Box>
            <Box
                sx={styles.mainImage}
                component={"img"}
                src="https://picsum.photos/450/450"
            />
        </Grid2>
    );
};

const styles = {
    container: {
        display: "flex",
        justifyContent: "start",
    },
    thumbnails: {
        display: "flex",
        flexDirection: "column",
        width: "25%",
        paddingRight: "1rem",
    },
    thumbnailImage: {
        marginBottom: ".5rem",
    },
    mainImage: {
        width: "75%",
    },
};
