import { Drawer, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { header_content } from "../../../../../data/general_texts";

const CustomDrawer = ({open, toggleDrawer}) => {

const links_list = header_content.main_links;

    return (
        <Drawer
            anchor={'top'}
            open={open}
            onClose={toggleDrawer(false)}
        >
            <List onClick={toggleDrawer(false)}>
                {links_list.map((link, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton href={link.link}>
                            <ListItemText primary={link.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

export default CustomDrawer;