import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import logo from '../../../../../assets/logo.png';

const ImageNavigation = () => {

    return (
        <Link to={'/'}>
            <Box sx={{
                borderRadius: '2rem'
            }} component={'img'} height={'5rem'} src={logo} />
        </Link>
    );
};

export default ImageNavigation;