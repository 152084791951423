import { Box, Grid2, Typography } from "@mui/material";

export default function ProductDescription() {
    const alianca = {
        descricao: "Aliança anatômica, meia cana, frisada, 5mm, com 1pt de Diamante",
        peso: "3g",
        largura: "5mm",
        espessura: "1.5mm",
        acabamento_externo: "Polido",
        conforto: "Anatômico",
        formato: "Meia cana",
        pedra: {
            quantidade: 1,
            tipo: "Diamante",
        },
    };

    const listaDescricao = [
        { label: "Descrição", value: alianca.descricao },
        { label: "Peso", value: alianca.peso },
        { label: "Largura", value: alianca.largura },
        { label: "Espessura", value: alianca.espessura },
        { label: "Acabamento externo", value: alianca.acabamento_externo },
        { label: "Conforto", value: alianca.conforto },
        { label: "Formato", value: alianca.formato },
        { label: "Pedra", value: `${alianca.pedra.quantidade}x ${alianca.pedra.tipo}` },
    ];

    return (
        <Grid2 size={12}>
            <Box sx={styles.box}>
                {listaDescricao.map((item, index) => (
                    <Typography key={index} sx={styles.typography}>
                        <strong>{item.label}:</strong> {item.value}
                    </Typography>
                ))}
            </Box>
        </Grid2>
    );
};

const styles = {
    box: {
        border: "2px solid black",
        borderRadius: 1,
        p: "1.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
    },
    typography: {
        mb: 1,
    },
};
