import { Box } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Layout({ children }) {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Header />
            <Box sx={styles.contentBox}>
                {children}
            </Box>
            <Footer />
        </>
    );
};

const styles = {
    contentBox: {
        overflow: 'hidden',
        pt: '7rem',
    }
};
