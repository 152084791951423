import { Box, Button, Checkbox, Grid2, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { FaChevronRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../components/AuthProvider";
import toast from "react-hot-toast";

export const LoginForm = () => {

    const {signIn} = useAuth();

    const [formData, setFormData] = useState({
        usuario: '',
        senha: '',
        concorda: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // signIn();
        toast.error(<Typography>Funcionalidade em desenvolvimento!</Typography>)
    };

    const styles = {
        textField: { mb: '1rem' },
        checkBoxContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: '1rem'
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    };

    return (
        <Grid2 size={{ xs: 12, md: 6 }}>
            <Box component={'form'}
                onSubmit={handleSubmit}
            >
                <TextField
                    required
                    fullWidth
                    sx={styles.textField}
                    label="Usuário*"
                    name="usuario"
                    value={formData.usuario}
                    onChange={handleChange}
                />
                <TextField
                    required
                    fullWidth
                    sx={styles.textField}
                    label="Senha*"
                    type="password"
                    name="senha"
                    value={formData.senha}
                    onChange={handleChange}
                />
                <Box sx={styles.checkBoxContainer}>
                    <Checkbox
                        required
                        name="concorda"
                        checked={formData.concorda}
                        onChange={handleChange}
                    />
                    <Typography>
                        Concordo com os Termos de Uso e Políticas de Privacidade
                    </Typography>
                </Box>
                <Box sx={styles.buttonContainer}>
                    <Button
                        startIcon={<FaChevronRight size={15} />}
                        variant="contained"
                        color="secondary"
                        type="submit"
                    >
                        Fazer Login
                    </Button>

                    <Link to="/esqueceu-senha">
                        Esqueceu a senha?
                    </Link>
                </Box>
            </Box>
        </Grid2>
    );
};