import Login from "../pages/Login";
import { useAuth } from "./AuthProvider";

export default function RouteWrapper({ loggedComponent, defaultComponent, isPrivate }) {

    const { user } = useAuth();

    if (user && !isPrivate) {

        return loggedComponent; //direciona para página privada.

    } else if (!user && isPrivate) {


        return <Login />; //direciona para página inicial.

    } else {

        return defaultComponent;
        
    };
};