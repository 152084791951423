import { Box, Link as MuiLink } from "@mui/material";
import { FaBars, FaWhatsapp } from "react-icons/fa6";
import ImageNavigation from "../ImageNavigation";
import { header_content } from "../../../../../data/general_texts";

const MainLinksMobile = ({toggleDrawer}) => {

    return (
        <Box sx={styles.main_links_mobile}>
            <MuiLink onClick={toggleDrawer(true)}>
                <FaBars color="white" size={28} />
            </MuiLink>
            <ImageNavigation />
            <Box>
                <MuiLink target="_blank" href={header_content.promotion_banner_content.cta_link}><FaWhatsapp color="white" size={30} /></MuiLink>
            </Box>
        </Box>
    );
};

const styles = {
    main_links_mobile: {
        display: {
            xs: 'flex',
            md: 'none'
        },
        justifyContent: 'space-between',
        alignItems: 'center',
        px: '1rem',
        py: '.25rem',
    },
};

export default MainLinksMobile;