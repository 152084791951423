import { Box, Typography } from "@mui/material";

import background from '../../../../assets/hero_bg.jpg';

export default function Banner() {

    return (
        <Box id="banner" sx={{
            width: '100vw',
            background: `url(${background})`,
            backgroundSize: '100%'
        }}>
            <Typography variant="h1" sx={{
                py: '5rem'
            }}>
                Catálogo de Produtos
            </Typography>
        </Box>
    )
}