import { Box, Button, Card, FormControl, Grid2, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { StyledSection } from "../../components/Section";
import { FaImage, FaPlus } from "react-icons/fa6";
import CustomContainer from "../../components/CustomContainer";

const styles = {
    section: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '90vh',
        py: '5rem'
    },
};

export default function CustomOrders() {

    return (
        <CustomContainer>
            <StyledSection sx={styles.section}>
                <Grid2 container spacing={5}>
                    <Grid2 size={12} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                    }}>
                        <Typography variant="h3" component={'h1'}>
                            Customização de Joia
                        </Typography>
                    </Grid2>
                    <Grid2 component={Card} size={12} spacing={3} p={'2rem'}>
                        <Typography mb={'1.5rem'} textAlign={'left'} fontSize={'1.25rem'} fontWeight={600}>
                            Informações da joia original
                        </Typography>
                        <Typography color="grey" textAlign={'left'} mb={'1rem'}>
                            Referência #00000
                        </Typography>
                        <Typography fontFamily={'DM Serif Display'} textAlign={'left'} fontSize={'1.75rem'}>
                            Aliança anatômica, meia cana, frisada, 5mm, com 1pt de Diamante
                        </Typography>
                        <Box sx={{
                            mt: '1.5rem',
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                md: 'row'
                            }
                        }}>
                            {
                                Array(3).fill('').map((_, index) => (
                                    <Box sx={{
                                        mb: {
                                            xs: 4,
                                            md: 0
                                        },
                                        mr: {
                                            xs: 0,
                                            md: 3
                                        }
                                    }} key={index} component={'img'} src="https://picsum.photos/200/200" />
                                ))
                            }
                        </Box>
                    </Grid2>
                    <Grid2 container component={Card} size={12} rowSpacing={6} p={'2rem'}>
                        <Grid2 size={12}>
                            <Typography mb={'.5rem'} textAlign={'left'} fontSize={'1.25rem'} fontWeight={600}>
                                Imagens de Referência
                            </Typography>
                            <Typography color="grey" mb={'1.5rem'} textAlign={'left'}>
                                Selecione até 3 imagens de referência.
                            </Typography>
                            <Box>
                                <Grid2 width={'100%'} container spacing={2}>
                                    <Grid2 display={'flex'} size={{ xs: 12, sm: 6, md: 4 }}>
                                        <Button color="secondary" startIcon={<FaImage />} variant="contained">
                                            Adicionar imagem
                                        </Button>
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </Grid2>

                        <Grid2 size={12}>
                            <Typography mb={'1.5rem'} textAlign={'left'} fontSize={'1.25rem'} fontWeight={600}>
                                Ficha técnica do produto
                            </Typography>
                            <Box >
                                <Grid2 width={'100%'} container spacing={5}>
                                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                        <FormControl required fullWidth>
                                            <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                            <Select
                                                variant="standard"
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Categoria"
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                        <FormControl required fullWidth>
                                            <InputLabel id="demo-simple-select-label">Material</InputLabel>
                                            <Select
                                                variant="standard"
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Material"
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                        <FormControl required fullWidth>
                                            <InputLabel id="demo-simple-select-label">Conforto</InputLabel>
                                            <Select
                                                variant="standard"
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Conforto"
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                        <FormControl required fullWidth>
                                            <InputLabel id="demo-simple-select-label">Formato</InputLabel>
                                            <Select
                                                variant="standard"
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Formato"
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid2>

                                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                        <TextField variant="standard" fullWidth label={'Lagura'} required type="number" />
                                    </Grid2>

                                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                        <TextField variant="standard" fullWidth label={'Espessura'} required type="number" />
                                    </Grid2>

                                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                        <FormControl required fullWidth>
                                            <InputLabel id="demo-simple-select-label">Tamanho do Aro</InputLabel>
                                            <Select
                                                variant="standard"
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Tamanho do Aro"
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid2>

                                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                        <TextField variant="standard" fullWidth label={'Gravação'} required />
                                    </Grid2>

                                    <Grid2 size={12}>
                                        <TextField variant="standard" multiline rows={4} fullWidth label={'Observação'} />
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </Grid2>

                        <Grid2 size={12}>
                            <Typography mb={'1.5rem'} textAlign={'left'} fontSize={'1.25rem'} fontWeight={600}>
                                Pedras
                            </Typography>
                            <Box>
                                <Grid2 width={'100%'} container spacing={2}>
                                    <Grid2 display={'flex'} size={{ xs: 12, sm: 6, md: 4 }}>
                                        <Button color="secondary" startIcon={<FaPlus />} variant="contained">
                                            Adicionar pedra
                                        </Button>
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={12} sx={{
                        display: 'flex',
                        justifyContent: 'end',
                    }}>
                        <Button sx={{ color: "black" }} variant="outlined">Voltar</Button>
                        <Button sx={{ ml: '1.5rem' }} variant="contained" color="secondary">Enviar Pedido</Button>
                    </Grid2>
                </Grid2>
            </StyledSection>
        </CustomContainer>
    );
};